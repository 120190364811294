import { IAchievement } from "common/types/achievements";
import { ApiPaging, ApiResponse, paramApi } from "../../types/common";
import { Api } from "../api";

export class AchievementsApi extends Api {
  async addAchievement(data: any, id?: string) {
    return await this.execute<ApiResponse<IAchievement>>(
      id ? "put" : "post",
      id ? "achievments/update" : "achievments/create",
      { _id: id, ...data },
      null,
      undefined
    );
  }

  async getPaging(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IAchievement[]>>>(
      "post",
      "achievments/paging",
      data,
      null,
      undefined
    );
  }

  async getById(id: string) {
    return await this.execute<ApiResponse<IAchievement>>(
      "get",
      `achievments/get-by-id/${id}`,
      null,
      null,
      undefined
    );
  }

  async delete(id: string) {
    return await this.execute<ApiResponse<any>>(
      "delete",
      `achievments/delete`,
      {
        _id: id,
      },
      null,
      undefined
    );
  }
}
