import { ConfigProvider } from "antd";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {
  Applications,
  Layout,
  Login,
  Programs,
  Universities,
  UniversitiesAdd,
  UniversitiesInfo,
  ProgramsInfo,
  ProgramsAdd,
  News,
  NewsInfo,
  NewsAdd,
  Employees,
  EmployeesAdd,
  EmployeesInfo,
  Branches,
  BranchesAdd,
  Users,
  UsersAdd,
  UsersInfo,
  Cities,
  CitiesAdd,
  Documents,
  DocumentsAdd,
  ApplicationsAdd,
  PaymentMethods,
  PaymentMethodsAdd,
  TransactionTypes,
  TransactionTypesAdd,
  Cashboxes,
  CashboxesAdd,
  Salaries,
  SaleFunnel,
  Achievements,
  AchievementsInfo,
  AchievementsAdd,
} from "./routes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <>Not found - go back</>,
    children: [
      {
        path: "applications",
        children: [
          {
            path: "list",
            element: <Applications />,
          },
          // {
          //   path: "info/:id",
          //   element: <UniversitiesInfo />,
          // },
          {
            path: "create/:id?",
            element: <ApplicationsAdd />,
          },
        ],
      },
      {
        path: "universities",
        children: [
          {
            path: "list",
            element: <Universities />,
          },
          {
            path: "info/:id",
            element: <UniversitiesInfo />,
          },
          {
            path: "create/:id?",
            element: <UniversitiesAdd />,
          },
        ],
      },
      {
        path: "programs",
        children: [
          {
            path: "list",
            element: <Programs />,
          },
          {
            path: "info/:id",
            element: <ProgramsInfo />,
          },
          {
            path: "create/:id?",
            element: <ProgramsAdd />,
          },
        ],
      },
      {
        path: "news",
        children: [
          {
            path: "list",
            element: <News />,
          },
          {
            path: "info/:id",
            element: <NewsInfo />,
          },
          {
            path: "create/:id?",
            element: <NewsAdd />,
          },
        ],
      },
      {
        path: "achievements",
        children: [
          {
            path: "list",
            element: <Achievements />,
          },
          {
            path: "info/:id",
            element: <AchievementsInfo />,
          },
          {
            path: "create/:id?",
            element: <AchievementsAdd />,
          },
        ],
      },
      {
        path: "finance",
        children: [
          {
            path: "cashboxes",
            children: [
              {
                path: "list",
                element: <Cashboxes />,
              },
              {
                path: "create/:id?",
                element: <CashboxesAdd />,
              },
            ],
          },
          {
            path: "salaries",
            element: <Salaries />,
          },
        ],
      },
      {
        path: "management",
        children: [
          {
            path: "employees",
            children: [
              {
                path: "list",
                element: <Employees />,
              },
              {
                path: "info/:id",
                element: <EmployeesInfo />,
              },
              {
                path: "create/:id?",
                element: <EmployeesAdd />,
              },
            ],
          },
          {
            path: "branches",
            children: [
              {
                path: "list",
                element: <Branches />,
              },
              {
                path: "create/:id?",
                element: <BranchesAdd />,
              },
            ],
          },
          {
            path: "cities",
            children: [
              {
                path: "list",
                element: <Cities />,
              },
              {
                path: "create/:id?",
                element: <CitiesAdd />,
              },
            ],
          },
          {
            path: "documents",
            children: [
              {
                path: "list",
                element: <Documents />,
              },
              {
                path: "create/:id?",
                element: <DocumentsAdd />,
              },
            ],
          },
          {
            path: "payment-methods",
            children: [
              {
                path: "list",
                element: <PaymentMethods />,
              },
              {
                path: "create/:id?",
                element: <PaymentMethodsAdd />,
              },
            ],
          },
          {
            path: "transaction-types",
            children: [
              {
                path: "list",
                element: <TransactionTypes />,
              },
              {
                path: "create/:id?",
                element: <TransactionTypesAdd />,
              },
            ],
          },
        ],
      },
      {
        path: "users",
        children: [
          {
            path: "list",
            element: <Users />,
          },
          {
            path: "info/:id",
            element: <UsersInfo />,
          },
          {
            path: "create/:id?",
            element: <UsersAdd />,
          },
        ],
      },
      {
        path: "analytics",
        children: [
          {
            path: "sale-funnel",
            element: <SaleFunnel />,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <>Not found - go back</>,
  },
]);

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#D94042",
          colorBgContainerDisabled: "rgb(246, 245, 247)",
        },
        components: {
          Button: {
            colorText: "#D94042",
            colorPrimary: "#D94042",
            algorithm: true,
          },
          Segmented: {
            itemSelectedBg: "#D94042",
            itemSelectedColor: "white",
          },
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
