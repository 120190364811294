import { Form, FormProps, TableProps } from "antd";
import api from "common/api";
import { ISalaryEmployee } from "common/types/employee";
import { IField } from "components/ui/filter/container/Filter";
import useQueryParams from "hooks/useQueryParams";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

interface DataType {
  key: number;
  full_name: string;
  gender: string;
  applications_count: number;
  branch: string;
  phone_number: string;
  _id: string;
}

export interface IEmployeesFilter {
  search: string;
}

const Context = () => {
  const [form] = Form.useForm();
  const { getAllParams } = useQueryParams();
  const [data, setData] = useState<ISalaryEmployee[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const filterFields: IField[] = [
    {
      type: "search",
      name: "search",
    },
  ];

  const columns: TableProps<DataType>["columns"] = useMemo(
    () => [
      {
        title: "Xodim F.I.O",
        key: "full_name",
        dataIndex: "full_name",
        // render: (data) =>
        //   `${data?.first_name} ${data?.last_name} ${data?.family_name}`,
      },
      // {
      //   title: "Oylik tushumi",
      //   dataIndex: "salary_monthly",
      //   key: "salary_monthly",
      // },
      // {
      //   title: "Foiz",
      //   dataIndex: "kpi_percent",
      //   key: "kpi_percent",
      // },
      // {
      //   title: "Avans",
      //   dataIndex: "advance",
      //   key: "advance",
      // },
      {
        title: "Oylik",
        dataIndex: "to_be_paid",
        key: "to_be_paid",
      },
      {
        title: "To'landi",
        dataIndex: "paid",
        key: "paid",
      },
      {
        title: "To'lanishi kerak",
        key: "must_pay",
        render: (row) => row.to_be_paid - row.paid,
      },
    ],
    [i18n.language]
  );

  const handleOpenDrawer = () => {
    setIsOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsOpen(false);
  };

  const onFinish: FormProps["onFinish"] = async (values) => {
    try {
      setLoadingSubmit(true);
      const response = await api.transaction.salary(values);
      setLoadingSubmit(false);
      setIsOpen(false);
      getPaging();
      form.resetFields();
    } catch (err) {
      setLoadingSubmit(false);
      console.log(err);
    }
  };

  const getPaging = async () => {
    try {
      setLoading(true);
      const response = await api.employee.salaryList(getAllParams);
      setData(response.data.data);
      setTotal(response.data.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPaging();
  }, [getAllParams]);

  return {
    state: {
      filterFields,
      columns,
      data,
      loading,
      isOpen,
      loadingSubmit,
      form,
      total,
    },
    actions: { handleOpenDrawer, handleCloseDrawer, onFinish },
  };
};

const SalariesContext = createContext<any>({ state: {}, actions: {} });

export const SalariesContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <SalariesContext.Provider value={value}>
      {children}
    </SalariesContext.Provider>
  );
};

export default function useSalariesContext() {
  return useContext<ReturnType<typeof Context>>(SalariesContext);
}
