import {
  faAngleLeft,
  faCircleCheck,
  faHourglassStart,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, Space, Typography } from "antd";
import paymeLogo from "assets/payme-logo.svg";
import trastbankLogo from "assets/trastbank.png";
import { Container } from "components/ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useApplicationsAddContext from "routes/applications/add/services/applicationsAddContext";
import styled from "styled-components";
import { format } from "utils/format";
import useApplicationPaymentContext from "../services/applicationPaymentContext";

const StyledPaymentMethod = styled.div`
  padding: 15px;
  border-radius: 12px;
  border: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  height: 60px;

  &:hover {
    background-color: #efefef;
  }
`;

const ApplicationPayment = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    state: { application },
    actions: { setCurrent },
  } = useApplicationsAddContext();

  const {
    state: {},
    actions: { setPayment },
  } = useApplicationPaymentContext();

  return (
    <div>
      {application?.state?.type === "contract" ||
      application?.state?.type === "second_payment" ? (
        <Container style={{ height: "calc(100vh - 230px)" }}>
          <Typography.Title level={4} style={{ textAlign: "center" }}>
            {application?.first_payment_paid === false
              ? t("applications.add.payment.first_payment")
              : application?.second_payment_paid === false
              ? t("applications.add.payment.second_payment")
              : ""}
          </Typography.Title>
          <Divider />
          <Typography.Text>
            {t("applications.add.payment.to_be_paid")}:{" "}
          </Typography.Text>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {format.money(
              application?.first_payment_paid === false
                ? application.program.application_fee
                : application?.second_payment_paid === false
                ? application.program.service_cost
                : 0,
              "UZS"
            )}
          </Typography.Title>

          <Typography.Paragraph style={{ marginTop: "50px" }}>
            {t("applications.add.payment.payment_methods")}
          </Typography.Paragraph>
          <Space wrap>
            <StyledPaymentMethod onClick={() => setPayment(false)}>
              <img src={paymeLogo} width={90} alt="payme-logo" />
            </StyledPaymentMethod>
            <StyledPaymentMethod onClick={() => setPayment(true)}>
              <img src={trastbankLogo} width={90} alt="trastbank-logo" />
            </StyledPaymentMethod>
          </Space>
        </Container>
      ) : application?.first_payment_paid &&
        application?.second_payment_paid ? (
        <Container style={{ height: "calc(100vh - 230px)" }}>
          <Typography.Title level={4} style={{ textAlign: "center" }}>
            {t("applications.add.payment.paid")}{" "}
            <FontAwesomeIcon icon={faCircleCheck} size="lg" color="#1EB53A" />
          </Typography.Title>
          <Divider />
        </Container>
      ) : (
        <Container style={{ height: "calc(100vh - 230px)" }}>
          <Typography.Title level={4} style={{ textAlign: "center" }}>
            {t("applications.add.payment.no_payment")}{" "}
            <FontAwesomeIcon icon={faHourglassStart} size="lg" color="orange" />
          </Typography.Title>
          <Divider />
        </Container>
      )}
      <Space
        style={{
          display: "flex",
          paddingTop: "20px",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={() => setCurrent((prev) => prev - 1)}
          disabled={false}
          icon={<FontAwesomeIcon icon={faAngleLeft} />}
          size="large"
          shape="circle"
          type="primary"
        />
      </Space>
    </div>
  );
};

export default ApplicationPayment;
