import { NewsContextProvider } from "../services/achievementsContext";
import Achievements from "./Achievements";

const index = () => {
  return (
    <NewsContextProvider>
      <Achievements />
    </NewsContextProvider>
  );
};

export default index;
