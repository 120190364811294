import { ApiPaging, ApiResponse, paramApi } from "common/types/common";
import { Api } from "../api";

export class NotificationApi extends Api {
  async getByApplicationId(payload: { application_id: string }) {
    return await this.execute<ApiResponse<any>>(
      "post",
      `notification/application/`,
      payload,
      null,
      undefined
    );
  }
}
