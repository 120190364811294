import { UploadOutlined } from "@ant-design/icons";
import {
  faAngleLeft,
  faAngleRight,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Space } from "antd";
import { UploadFile } from "components/form";
import { Button, Container } from "components/ui";
import { useTranslation } from "react-i18next";
import useApplicationDocumentsContext from "../services/applicationDocumentsContext";
import { useParams } from "react-router-dom";
import useApplicationsAddContext from "routes/applications/add/services/applicationsAddContext";
import { useTransition } from "react";

const ApplicationDocuments = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const {
    state: { current, userId },
    actions: { setCurrent },
  } = useApplicationsAddContext();
  const {
    state: { documents, form },
    actions: { onFinish, uploadDoc },
  } = useApplicationDocumentsContext();

  return (
    <Form form={form} name="documents" onFinish={onFinish}>
      <Container height={false} style={{ height: "calc(100vh - 230px)" }}>
        {documents?.map((document, index) => (
          <Form.Item<any>
            key={index}
            name={document._id}
            // rules={[{ required: true }]}
            // label={document.name[i18n.language as "uz" | "en" | "ru"]}
          >
            <UploadFile
              accept="*"
              form={form}
              required
              name={document._id}
              label={document.name[i18n.language as "uz" | "en" | "ru"]}
              placeholder={
                document.description[i18n.language as "uz" | "en" | "ru"]
              }
              afterUpload={(name, data) => {
                if (userId && name)
                  uploadDoc({ user_id: userId, doc_id: name, value: data });
              }}
            >
              <Button icon={<UploadOutlined />}>
                {t("users.documents.upload")}
              </Button>
            </UploadFile>
          </Form.Item>
        ))}
      </Container>
      <Space
        style={{
          display: "flex",
          paddingTop: "20px",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={() => setCurrent((prev) => prev - 1)}
          disabled={false}
          icon={<FontAwesomeIcon icon={faAngleLeft} />}
          size="large"
          shape="circle"
          type="primary"
        />
        <Button
          onClick={() => setCurrent((prev) => prev + 1)}
          disabled={false}
          icon={<FontAwesomeIcon icon={faAngleRight} />}
          size="large"
          shape="circle"
          type="primary"
        />
      </Space>
    </Form>
  );
};

export default ApplicationDocuments;
