import { message } from "antd";
import api from "common/api";
import { IAchievement } from "common/types/achievements";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

const Context = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<IAchievement>();
  const [loading, setLoading] = useState(false);

  const getById = async (id: string) => {
    try {
      setLoading(true);
      const response = await api.achievements.getById(id);
      setData(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    if (id)
      try {
        const response = await api.achievements.delete(id);
        message.success("Natija o'chirildi");
        navigate(-1);
      } catch (err) {
        console.log(err);
      }
    else navigate(-1);
  };

  useEffect(() => {
    if (id) getById(id);
  }, []);
  return {
    state: { data, loading },
    actions: { handleDelete },
  };
};

const AchievementsInfoContext = createContext<any>({ state: {}, actions: {} });

export const AchievementsInfoContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <AchievementsInfoContext.Provider value={value}>
      {children}
    </AchievementsInfoContext.Provider>
  );
};

export default function useAchievementsInfoContext() {
  return useContext<ReturnType<typeof Context>>(AchievementsInfoContext);
}
