import { Col, Row } from "antd";
import { IApplication } from "common/types/applications";
import { FieldInfo } from "components/ui";
import React, { memo } from "react";
import term from "utils/term";

const PersonalInfo = ({ task }: { task: IApplication | undefined }) => {
  return task ? (
    <div style={{ height: "100%", overflowY: "scroll", overflowX: "hidden" }}>
      <Row gutter={[20, 20]}>
        <Col xs={24} md={6}>
          <img
            src={`${process.env.REACT_APP_BASE_URL}${task.image_url}`}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: 5,
            }}
          />
        </Col>
        <Col xs={24} md={18}>
          <Row gutter={[20, 20]}>
            <Col xs={12} md={8}>
              <FieldInfo type="text" title="Ismi" value={task.first_name} />
            </Col>
            <Col xs={12} md={8}>
              <FieldInfo
                type="text"
                title="Familiyasi"
                value={task.last_name}
              />
            </Col>
            <Col xs={12} md={8}>
              <FieldInfo
                type="text"
                title="Otasining ismi"
                value={task.family_name}
              />
            </Col>

            <Col xs={12} md={8}>
              <FieldInfo
                type="date"
                title="Tug'ilgan sanasi"
                value={task.birth_date}
              />
            </Col>
            <Col xs={12} md={8}>
              <FieldInfo
                type="text"
                title="Jinsi"
                value={term.gender(task.gender)}
              />
            </Col>
            <Col xs={12} md={8}>
              <FieldInfo type="text" title="Dini" value={task.religion} />
            </Col>

            <Col xs={12} md={8}>
              <FieldInfo type="text" title="Millati" value={task.nation} />
            </Col>
            <Col xs={12} md={8}>
              <FieldInfo
                type="text"
                title="Passport raqami"
                value={task.passport_number}
              />
            </Col>
            <Col xs={12} md={8}>
              <FieldInfo
                type="date"
                title="Amal qilish muddati"
                value={task.valid_until}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={8}>
          <FieldInfo
            type="text"
            title="Oilaviy holati"
            value={term.marital(task.marital_status)}
          />
        </Col>
        <Col xs={12} md={8}>
          <FieldInfo
            type="text"
            title="Tamomlagan ta’lim muassasi"
            value={task.previous_university}
          />
        </Col>
        <Col xs={12} md={8}>
          <FieldInfo
            type="text"
            title="Telegram/Wechat"
            value={task.social_media_link}
          />
        </Col>
        <Col xs={24} md={24}>
          <FieldInfo type="text" title="To'liq manzil" value={task.address} />
        </Col>
        <Col xs={12} md={8}>
          <FieldInfo
            type="text"
            title="Avval Xitoyda bo’lganmi?"
            value={task.lived_in_china ? "Ha" : "Yo'q"}
          />
        </Col>
        <Col xs={12} md={16}>
          {task.lived_in_china && (
            <FieldInfo
              type="text"
              title="Tashrifining maqsadi"
              value={task.living_purpose}
            />
          )}
        </Col>
        <Col xs={12} md={8}>
          <FieldInfo
            type="text"
            title="Xozirda Xitoydamisiz?"
            value={task.currently_in_china ? "Ha" : "Yo'q"}
          />
        </Col>
        <Col xs={12} md={16}>
          {task.currently_in_china && (
            <FieldInfo
              type="text"
              title="Hozirda tahsil olayotgan ta’lim muassasasi"
              value={task.current_university}
            />
          )}
        </Col>
      </Row>
    </div>
  ) : null;
};

export default memo(PersonalInfo);
