import {
  faBriefcase,
  faBuildingColumns,
  faChartLine,
  faChartPie,
  faFileLines,
  faGraduationCap,
  faNewspaper,
  faTrophy,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Layout, Menu, MenuProps, theme } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import base64 from "utils/base64";
import moment from "moment";

const util = (str: any) => base64.encode(JSON.stringify(str));

const StyledMenu = styled(Menu)`
  .ant-menu-item,
  .ant-menu-submenu .ant-menu-submenu-title {
    display: flex;
    align-items: center;
  }
`;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const iconStyle: React.CSSProperties = {
    transition: "0.5s",
    padding: collapsed ? 0 : "5px",
    backgroundColor: collapsed ? "transparent" : "#D94042",
    color: collapsed ? "#D94042" : "white",
    borderRadius: collapsed ? 0 : 7,
  };

  const items2: MenuProps["items"] = [
    {
      key: "applications",
      icon: <FontAwesomeIcon icon={faFileLines} style={iconStyle} />,
      label: <Link to={`/applications/list`}>Arizalar</Link>,
    },
    {
      key: "universities",
      icon: <FontAwesomeIcon icon={faBuildingColumns} style={iconStyle} />,
      label: (
        <Link to={`/universities/list?limit=${util(10)}&page=${util(1)}`}>
          Universitetlar
        </Link>
      ),
    },
    {
      key: "programs",
      icon: <FontAwesomeIcon icon={faGraduationCap} style={iconStyle} />,
      label: (
        <Link to={`/programs/list?limit=${util(10)}&page=${util(1)}`}>
          Dasturlar
        </Link>
      ),
    },
    {
      key: "news",
      icon: <FontAwesomeIcon icon={faNewspaper} style={iconStyle} />,
      label: (
        <Link to={`/news/list?limit=${util(10)}&page=${util(1)}`}>
          Yangiliklar
        </Link>
      ),
    },
    {
      key: "achievements",
      icon: <FontAwesomeIcon icon={faTrophy} style={iconStyle} />,
      label: (
        <Link to={`/achievements/list?limit=${util(10)}&page=${util(1)}`}>
          Natijalar
        </Link>
      ),
    },
    {
      key: "users",
      icon: <FontAwesomeIcon icon={faUserGraduate} style={iconStyle} />,
      label: (
        <Link to={`/users/list?limit=${util(10)}&page=${util(1)}`}>
          Foydalanuvchilar
        </Link>
      ),
    },
    {
      key: "finance",
      icon: <FontAwesomeIcon icon={faChartLine} style={iconStyle} />,
      label: "Moliya",
      children: [
        {
          label: <Link to={`/finance/cashboxes/list`}>Kassalar</Link>,
          key: "cashboxes",
        },
        {
          label: (
            <Link to={`/finance/salaries/?limit=${util(10)}&page=${util(1)}`}>
              Maosh chiqarish
            </Link>
          ),
          key: "salary",
        },
      ],
    },
    {
      key: "management",
      icon: <FontAwesomeIcon icon={faBriefcase} style={iconStyle} />,
      label: "Boshqaruv",
      children: [
        {
          label: (
            <Link
              to={`/management/employees/list?limit=${util(10)}&page=${util(
                1
              )}`}
            >
              Xodimlar
            </Link>
          ),
          key: "employees",
        },
        {
          label: (
            <Link
              to={`/management/branches/list?limit=${util(10)}&page=${util(1)}`}
            >
              Filiallar
            </Link>
          ),
          key: "branches",
        },
        {
          label: (
            <Link
              to={`/management/cities/list?limit=${util(10)}&page=${util(1)}`}
            >
              Shaharlar
            </Link>
          ),
          key: "cities",
        },
        {
          label: (
            <Link
              to={`/management/documents/list?limit=${util(10)}&page=${util(
                1
              )}`}
            >
              Dokumentlar
            </Link>
          ),
          key: "documents",
        },
        {
          label: (
            <Link
              to={`/management/payment-methods/list?limit=${util(
                10
              )}&page=${util(1)}`}
            >
              To'lov usullari
            </Link>
          ),
          key: "payment-methods",
        },
        {
          label: (
            <Link
              to={`/management/transaction-types/list?limit=${util(
                10
              )}&page=${util(1)}`}
            >
              Tranzaksiya turlari
            </Link>
          ),
          key: "transaction-types",
        },
      ],
    },
    {
      key: "analytics",
      icon: <FontAwesomeIcon icon={faChartPie} style={iconStyle} />,
      label: "Hisobotlar",
      children: [
        {
          label: (
            <Link
              to={`/analytics/sale-funnel?date_from=${util(
                moment().startOf("month").toISOString()
              )}&date_to=${util(moment().toISOString())}`}
            >
              Sotuv voronkasi
            </Link>
          ),
          key: "sale-funnel",
        },
      ],
    },
  ];
  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      width={250}
      trigger={null}
    >
      <StyledMenu style={{ height: "100%", borderRight: 0 }} items={items2} />
      <Button
        type="dashed"
        shape="circle"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          fontSize: "16px",
          position: "absolute",
          width: "40px",
          height: "40px",
          right: "20px",
          bottom: 20,
        }}
      />
    </Layout.Sider>
  );
};

export default Sidebar;
