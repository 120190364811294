import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { DragStart, DropResult } from "react-beautiful-dnd";
import { Data } from "./boardTypes";
import { IBoard } from "../container";
import { IState } from "common/types/common";
import api from "common/api";
import { Form, FormProps } from "antd";

const Context = ({ states }: IBoard) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<Data>();
  const [modal, setModal] = useState<
    | {
        isOpen: boolean;
        state: "incomplete" | "second_payment";
        _id?: string;
        state_id?: string;
        position?: number;
        data: Data;
        loading: boolean;
      }
    | undefined
  >(undefined);

  const onDragEnd = async (result: DropResult) => {
    if (data) {
      const { destination, source, draggableId } = result;

      if (!destination) {
        return;
      }

      const state = states?.find(
        (state) => state._id === destination.droppableId
      );

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      const start = data?.columns?.[source.droppableId];
      const finish = data?.columns?.[destination.droppableId];

      if (start && finish) {
        if (start === finish) {
          const newTaskIds = Array.from(start.taskIds ?? []);
          newTaskIds.splice(source.index, 1);
          newTaskIds.splice(destination.index, 0, draggableId);

          const newColumn = {
            ...start,
            taskIds: newTaskIds,
          };

          const newState = {
            ...data,
            columns: {
              ...data.columns,
              [newColumn._id]: newColumn,
            },
            tasks: {
              ...data.tasks,
              [draggableId]: {
                ...data.tasks[draggableId],
                position: destination.index + 1,
                state: finish,
                state_id: destination.droppableId,
              },
            },
          };

          setData(newState);
          updatePosition({
            _id: draggableId,
            state_id: destination.droppableId,
            position: destination.index + 1,
          });
          return;
        }

        const startTaskIds = Array.from(start.taskIds ?? []);
        startTaskIds.splice(source.index, 1);
        const newStart = {
          ...start,
          taskIds: startTaskIds,
        };

        const finishTaskIds = Array.from(finish.taskIds ?? []);
        finishTaskIds.splice(destination.index, 0, draggableId);
        const newFinish = {
          ...finish,
          taskIds: finishTaskIds,
        };

        const newState = {
          ...data,
          columns: {
            ...data.columns,
            [newStart._id]: newStart,
            [newFinish._id]: newFinish,
          },
          tasks: {
            ...data.tasks,
            [draggableId]: {
              ...data.tasks[draggableId],
              position: destination.index + 1,
              state: finish,
              state_id: destination.droppableId,
            },
          },
        };

        if (state?.type === "incomplete" || state?.type === "second_payment") {
          setData(newState);
          setModal({
            isOpen: true,
            state: state.type,
            _id: draggableId,
            state_id: destination.droppableId,
            position: destination.index + 1,
            data: data,
            loading: false,
          });
        } else {
          setData(newState);
          const response = await updatePosition({
            _id: draggableId,
            state_id: destination.droppableId,
            position: destination.index + 1,
          });
          if (!response) {
            setData(data);
          }
        }
      }
    }
  };

  const updatePosition = async (payload: {
    _id: string;
    state_id: string;
    position: number;
    reason?: string;
    file_url?: string;
  }) => {
    try {
      const response = await api.application.updatePosition(payload);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  useEffect(() => {
    const result = states?.reduce((acc, item) => {
      acc[item._id] = item;
      return acc;
    }, {} as { [key: string]: IState });

    setData({ tasks: {}, columns: result });
  }, [states]);

  const onFinish: FormProps["onFinish"] = async (values) => {
    if (modal && modal._id && modal.state_id && modal.position && modal.data) {
      setModal((prev) => (prev ? { ...prev, loading: true } : prev));
      const response = await updatePosition({
        _id: modal._id,
        state_id: modal.state_id,
        position: modal.position,
        ...(modal.state === "incomplete"
          ? { reason: values?.reason }
          : modal.state === "second_payment"
          ? { file_url: values?.file_url }
          : {}),
      });
      if (!response) {
        setData(modal.data);
      }
      setModal((prev) => (prev ? { ...prev, loading: false } : prev));
      setModal(undefined);
    }
  };

  const onCancel = () => {
    setData(modal?.data);
    setModal(undefined);
  };

  return {
    state: { data, modal, form },
    actions: { onDragEnd, setData, setModal, onFinish, onCancel },
  };
};

const BoardContext = createContext<any>({ state: {}, actions: {} });

export const BoardContextProvider: FC<IBoard> = ({ children, ...props }) => {
  const value = Context(props);
  return (
    <BoardContext.Provider value={value}>{children}</BoardContext.Provider>
  );
};

export default function useBoardContext() {
  return useContext<ReturnType<typeof Context>>(BoardContext);
}
