import { TableProps } from "antd";
import api from "common/api";
import { INews } from "common/types/news";
import { IField } from "components/ui/filter/container/Filter";
import useQueryParams from "hooks/useQueryParams";
import moment from "moment";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface DataType {
  key: number;
  university: string;
  city: string;
  type: "public" | "private";
  program_count: number;
  applications_count: number;
  _id: string;
}

export interface INewsFilter {
  search: string;
  city: string[];
  type: string[];
}

const Context = () => {
  const navigate = useNavigate();
  const { getAllParams } = useQueryParams();
  const [data, setData] = useState<INews[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();

  const filterFields: IField[] = [
    {
      type: "date",
      label: "Sana",
      name: "date",
    },
    {
      type: "search",
      name: "search",
    },
  ];

  const columns: TableProps<DataType>["columns"] = useMemo(
    () => [
      {
        title: "Nomi",
        dataIndex: ["title", i18n.language],
        key: "name",
      },
      {
        title: "Sanasi",
        dataIndex: "date",
        key: "date",
        render: (date) => moment.utc(date).format("l"),
      },
    ],
    [i18n.language]
  );

  const onRowClick = (record: DataType, rowIndex: number | undefined) => {
    navigate(`/news/info/${record._id}`);
  };

  const getPaging = async () => {
    try {
      setLoading(true);
      const response = await api.news.getPaging(getAllParams);
      setData(response.data.data);
      setTotal(response.data.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPaging();
  }, [getAllParams]);

  return {
    state: { filterFields, columns, data, loading, total },
    actions: { onRowClick },
  };
};

const NewsContext = createContext<any>({ state: {}, actions: {} });

export const NewsContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return <NewsContext.Provider value={value}>{children}</NewsContext.Provider>;
};

export default function useNewsContext() {
  return useContext<ReturnType<typeof Context>>(NewsContext);
}
