import { Container } from "components/ui";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import { IBoard } from ".";
import Lane from "../components/Lanes/container";
import useBoardContext from "../services/boardContext";
import { Task } from "../services/boardTypes";
import { Button, Flex, Form, Input, Modal, Space } from "antd";
import { useTranslation } from "react-i18next";
import { UploadFile } from "components/form";
import { UploadOutlined } from "@ant-design/icons";

const BoardCanvas = styled.div`
  display: block;
  height: 100%;
  overflow: hidden;
`;

const BoardOl = styled.ol`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  list-style: none;
  gap: 20px;
`;

const Board = (props: IBoard) => {
  const { t } = useTranslation();
  const {
    state: { data, modal, form },
    actions: { onDragEnd, setModal, onFinish, onCancel },
  } = useBoardContext();
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container height filter background={false} padding={false}>
        {/* <BoardCanvas className="board-canvas"> */}
        <BoardOl id="board">
          {data?.columns
            ? Object.entries(data?.columns).map(([columnId, column]) => {
                const tasks = column.taskIds
                  ? column.taskIds.map((taskId) => data.tasks?.[taskId])
                  : undefined;
                return <Lane key={column._id} column={column} tasks={tasks} />;
              })
            : "Hech narsa yo'q"}
        </BoardOl>
        {/* </BoardCanvas> */}
      </Container>
      <Modal
        open={modal?.isOpen}
        onCancel={onCancel}
        footer
        title={t("applications.list.board.form.title")}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          {modal?.state === "incomplete" ? (
            <Form.Item
              name="reason"
              label={t("applications.list.board.form.reason")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          ) : (
            <Form.Item<any> name="file_url" rules={[{ required: true }]}>
              <UploadFile
                form={form}
                required
                name={"file_url"}
                label={t("applications.list.board.form.file")}
                accept="*"
              >
                <Button icon={<UploadOutlined />}>
                  {t("users.documents.upload")}
                </Button>
              </UploadFile>
            </Form.Item>
          )}
          <Flex justify="flex-end" style={{ marginTop: "10px" }} gap={10}>
            <Button onClick={onCancel}>{t("components.form.cancel")}</Button>
            <Button type="primary" htmlType="submit" loading={modal?.loading}>
              {t("components.form.submit")}
            </Button>
          </Flex>
        </Form>
      </Modal>
    </DragDropContext>
  );
};

export default Board;
