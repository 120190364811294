import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableProps } from "antd";
import api from "common/api";
import { Button } from "components/ui";
import { IField } from "components/ui/filter/container/Filter";
import useQueryParams from "hooks/useQueryParams";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

interface DataType {
  key: number;
  full_name: string;
  gender: string;
  applications_count: number;
  branch: string;
  phone_number: string;
  _id: string;
}

export interface IEmployeesFilter {
  search: string;
}

const Context = () => {
  const navigate = useNavigate();
  const { getAllParams } = useQueryParams();
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();

  const filterFields: IField[] = [
    {
      type: "search",
      name: "search",
    },
  ];

  const columns: TableProps<DataType>["columns"] = useMemo(
    () => [
      {
        title: "Nomi",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Manzil",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "Telefon raqami",
        dataIndex: "phone_number",
        key: "phone_number",
      },
      {
        dataIndex: "_id",
        key: "_id",
        render: (id) => (
          <Link to={`/management/branches/create/${id}`}>
            <Button
              type="primary"
              icon={<FontAwesomeIcon icon={faEdit} />}
              style={{ backgroundColor: "orange" }}
            >
              O'zgartirish
            </Button>
          </Link>
        ),
      },
    ],
    [i18n.language]
  );

  const getPaging = async () => {
    try {
      setLoading(true);
      const response = await api.branch.getPaging(getAllParams);
      setData(response.data.data);
      setTotal(response.data.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPaging();
  }, [getAllParams]);

  return {
    state: { filterFields, columns, data, loading, total },
    actions: {},
  };
};

const BranchesContext = createContext<any>({ state: {}, actions: {} });

export const BranchesContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <BranchesContext.Provider value={value}>
      {children}
    </BranchesContext.Provider>
  );
};

export default function useBranchesContext() {
  return useContext<ReturnType<typeof Context>>(BranchesContext);
}
