import { AchievementsInfoContextProvider } from "../services/achievementsInfoContext";
import AchievementsInfo from "./AchievementsInfo";

const index = () => {
  return (
    <AchievementsInfoContextProvider>
      <AchievementsInfo />
    </AchievementsInfoContextProvider>
  );
};

export default index;
