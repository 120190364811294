import { Drawer, Form, Input, InputNumber, Space } from "antd";
import {
  CashboxSelect,
  PaymentMethodSelect,
  TransactionTypeSelect,
} from "components/form";
import { Button } from "components/ui";
import useCashboxesContext from "../../services/cashboxesContext";

interface DataType {
  destination_cashbox_id?: string;
  transaction_type_id?: string;
  payment_method_id: string;
  amount: number;
  description: string;
}

const Action = () => {
  const {
    state: { action, form, selected },
    actions: { handleCloseAction, onFinish },
  } = useCashboxesContext();
  return (
    <Drawer
      title={
        action?.type === "income"
          ? "Kirim qilish"
          : action?.type === "expense"
          ? "Chiqim qilish"
          : action?.type === "transfer"
          ? "Kassadan kassaga ko'chirish"
          : null
      }
      onClose={handleCloseAction}
      open={action?.open}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Space direction="vertical" style={{ width: "100%" }}>
          {action?.type === "transfer" ? (
            <CashboxSelect<DataType>
              form={form}
              name="destination_cashbox_id"
              rules={[{ required: true }]}
              preprocessOptions={(options) =>
                options.filter((option) => option._id !== selected)
              }
            />
          ) : action?.type === "expense" || action?.type === "income" ? (
            <TransactionTypeSelect<DataType>
              form={form}
              name="transaction_type_id"
              rules={[{ required: true }]}
              extraParams={{ type: action?.type }}
              dependency={[action?.type]}
            />
          ) : (
            <></>
          )}
          <PaymentMethodSelect<DataType>
            form={form}
            name="payment_method_id"
            rules={[{ required: true }]}
          />
          <Form.Item<DataType>
            name="amount"
            label="Miqdor"
            rules={[{ required: true }]}
            style={{ margin: 0 }}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item<DataType>
            name="description"
            label="Izoh"
            // rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right" }}
            loading={action?.loading}
          >
            Saqlash
          </Button>
        </Space>
      </Form>
    </Drawer>
  );
};

export default Action;
