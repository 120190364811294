import { useRef, useState } from "react";
import SignatureCapture from "./SignatureCapture";

interface ContractTemplateProps {
  content: string;
}

const ContractTemplate: React.FC<ContractTemplateProps> = ({ content }) => {
  const printRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <div
        ref={printRef}
        dangerouslySetInnerHTML={{ __html: content }}
        className="contract-template"
      />
      <SignatureCapture printRef={printRef} />
    </div>
  );
};

export default ContractTemplate;
