import type { TablePaginationConfig, TableProps } from "antd";
import { Table as AntTable } from "antd";
import useQueryParams from "hooks/useQueryParams";
import styled from "styled-components";

const StyledTable = styled(AntTable)`
  .ant-pagination {
    padding-right: 20px;
  }
`;

const Table = (
  props: TableProps & {
    total: number;
  }
) => {
  const { getParam, setParam } = useQueryParams();
  const onPageChange: TablePaginationConfig["onChange"] = (page) => {
    setParam({ name: "page", value: page });
  };
  return (
    <StyledTable
      {...props}
      sticky={true}
      scroll={{ y: window.innerHeight - 275 }}
      pagination={{
        current: Number(getParam("page")),
        pageSize: Number(getParam("limit")),
        total: props.total,
        onChange: onPageChange,
      }}
    />
  );
};

export default Table;
