import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import React, { RefObject } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

interface SignatureCaptureProps {
  printRef: RefObject<HTMLDivElement>;
}

const SignatureCapture: React.FC<SignatureCaptureProps> = ({ printRef }) => {
  const { t } = useTranslation();

  const print = useReactToPrint({
    contentRef: printRef,
  });

  const handlePrint = () => {
    print();
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <Button onClick={handlePrint} icon={<FontAwesomeIcon icon={faPrint} />}>
        {t("applications.add.contract.print")}
      </Button>
    </div>
  );
};

export default SignatureCapture;
