import {
  faBackspace,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Row, Space } from "antd";
import { Button, Container } from "components/ui";
import { useParams } from "react-router-dom";
import useAchievementsAddContext from "../services/achievementsAddContext";
import { Field } from "components/form";

const AchievementsAdd = () => {
  const { id } = useParams();

  const {
    state: { form },
    actions: { onFinish, handleDelete },
  } = useAchievementsAddContext();
  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Row gutter={[20, 20]}>
        <Col xs={24} md={19}>
          <Container padding={false} background={false}>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <Container overflow={false} height={false}>
                <Row gutter={[20, 20]}>
                  <Col xs={24}>
                    <Field
                      form={form}
                      title="Natija rasmi"
                      name="image_url"
                      type="image"
                      required
                      // language={true}
                      // style={{ objectFit: "contain" }}
                    />
                  </Col>
                  <Col xs={24}>
                    <Field
                      form={form}
                      title="Student ismi"
                      name="student_name"
                      type="input"
                      required
                      // language={true}
                    />
                  </Col>
                  <Col xs={24}>
                    <Field
                      form={form}
                      title="Izohi"
                      name="comment"
                      type="textarea"
                      language={true}
                    />
                  </Col>
                </Row>
              </Container>
            </Space>
          </Container>
        </Col>
        <Col xs={24} md={5}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Button
              icon={<FontAwesomeIcon icon={faEdit} />}
              block
              type="primary"
              style={{ backgroundColor: "#1EB53A" }}
              htmlType="submit"
            >
              Saqlash
            </Button>
            <Button
              icon={<FontAwesomeIcon icon={id ? faTrash : faBackspace} />}
              block
              type={id ? "primary" : "default"}
              onClick={handleDelete}
            >
              {id ? "O'chirish" : "Bekor qilish"}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default AchievementsAdd;
