import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { IApplication } from "common/types/applications";
import { ITransaction } from "common/types/transaction";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { format } from "utils/format";

const Payments = ({ task }: { task: IApplication | undefined }) => {
  const { t } = useTranslation();
  const transactionColumns: ColumnsType<ITransaction> = [
    {
      title: t("applications.info.transactions.transaction_number"),
      dataIndex: "number",
      key: "number",
    },
    {
      title: t("applications.info.transactions.aim_of_payment"),
      dataIndex: "custom_id",
      key: "custom_id",
      render: (item) => t(`applications.info.transactions.${item}`),
    },
    {
      title: t("applications.info.transactions.amount"),
      dataIndex: "amount",
      key: "amount",
      render: (amount) => format.money(amount, "UZS"),
    },
    {
      title: t("applications.info.transactions.payment_method"),
      dataIndex: ["payment_method", "name"],
      key: "payment_method.name",
    },
    {
      title: t("applications.info.transactions.created_date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment.utc(date).format("MM/D/YYYY, HH:mm:ss"),
    },
  ];

  return (
    <Table
      dataSource={task?.transactions}
      columns={transactionColumns}
      bordered
      pagination={false}
    />
  );
};

export default Payments;
