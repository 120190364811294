import { Avatar, Col, Divider, List, Row, Typography } from "antd";
import { IApplication } from "common/types/applications";
import { INotification } from "common/types/notifications";
import { FieldInfo } from "components/ui";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ApplicationInfo = ({
  task,
  notifications,
  notificationsLoading,
}: {
  task: IApplication | undefined;
  notifications: INotification[] | undefined;
  notificationsLoading: boolean;
}) => {
  const { t, i18n } = useTranslation();

  return task ? (
    <div style={{ height: "100%", overflowY: "scroll", overflowX: "hidden" }}>
      <Row gutter={[20, 20]}>
        <Col xs={24} md={8}>
          <FieldInfo type="text" title="Ariza raqami" value={task.number} />
        </Col>
        <Col xs={24} md={8}>
          <FieldInfo
            type="text"
            title="Dastur nomi"
            value={task?.program?.name[i18n.language as "uz" | "en" | "ru"]}
          />
        </Col>
        <Col xs={24} md={8}>
          <FieldInfo
            type="text"
            title="Dastur ID"
            value={task.program?.number}
          />
        </Col>
        <Col xs={24} md={8}>
          <FieldInfo
            type="date"
            title="Berilgan sana"
            value={task.created_at}
          />
        </Col>
        <Col xs={24} md={8}>
          <FieldInfo
            type="date"
            title="Ohirgi o'zgartirish kiritilgan sana"
            value={task.updated_at}
          />
        </Col>
        <Col xs={24} md={8}>
          <FieldInfo type="text" title="Xolati" value={task.state?.name} />
        </Col>
      </Row>
      <Divider dashed />
      <Typography.Title level={5}>Ariza tarixi</Typography.Title>
      <List
        itemLayout="horizontal"
        dataSource={notifications}
        loading={notificationsLoading}
        renderItem={(notification, index) => (
          <List.Item>
            <List.Item.Meta
              // avatar={<Avatar>AF</Avatar>}
              title={
                notification.state.type !== "canceled"
                  ? notification.state.type === "incomplete"
                    ? t("applications.info.incomplete_text", {
                        reason: notification.reason,
                        state: t(`constants.states.${notification.state.type}`),
                      })
                    : t(`applications.info.main_text`, {
                        state: t(`constants.states.${notification.state.type}`),
                      })
                  : t("applications.info.canceled_text", {})
              }
            />
            <Typography.Text type="secondary">
              {moment().format("l")}
            </Typography.Text>
          </List.Item>
        )}
      />
    </div>
  ) : null;
};

export default ApplicationInfo;
