import {
  faAngleLeft,
  faAngleRight,
  faHourglassStart,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Space, Typography } from "antd";
import { Container } from "components/ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useApplicationsAddContext from "routes/applications/add/services/applicationsAddContext";
import ContractTemplate from "../components/ContractTemplate";

const { useBreakpoint } = Grid;

const ApplicationContract: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const screens = useBreakpoint();

  const {
    state: { application },
    actions: { setCurrent },
  } = useApplicationsAddContext();

  return (
    <div style={{ width: "100%" }}>
      <Container
        height={false}
        style={{ height: screens.xs ? "" : "calc(100vh - 230px)" }}
      >
        {application?.contract_id ? (
          <ContractTemplate content={application.contract?.content} />
        ) : (
          <Typography.Title level={3} style={{ textAlign: "center" }}>
            {t("applications.add.contract.waiting")}{" "}
            <FontAwesomeIcon icon={faHourglassStart} color="orange" />
          </Typography.Title>
        )}
      </Container>
      <Space
        style={{
          display: "flex",
          paddingTop: "20px",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={() => {
            setCurrent((prev) => prev - 1);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          disabled={false}
          icon={<FontAwesomeIcon icon={faAngleLeft} />}
          size="large"
          shape="circle"
          type="primary"
        />
        <Button
          onClick={() => {
            setCurrent((prev) => prev + 1);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          disabled={!!!application?.contract_id}
          icon={<FontAwesomeIcon icon={faAngleRight} />}
          size="large"
          shape="circle"
          type="primary"
        />
      </Space>
    </div>
  );
};

export default ApplicationContract;
