import api from "common/api";
import React, { FC, createContext, useContext } from "react";
import useApplicationsAddContext from "routes/applications/add/services/applicationsAddContext";

const Context = () => {
  const {
    state: { application },
    actions: { setApplication },
  } = useApplicationsAddContext();

  const setPayment = async (is_manual?: boolean) => {
    if (application) {
      try {
        const response = await api.application.getPayme({
          application_id: application?._id,
          payment_type: application?.first_payment_paid
            ? "second_payment"
            : "first_payment",
          is_manual: is_manual,
        });
        if (is_manual) {
          setApplication((prev) =>
            prev
              ? {
                  ...prev,
                  first_payment_paid: response.data.first_payment_paid,
                  second_payment_paid: response.data.second_payment_paid,
                  state: {
                    ...prev.state,
                    type:
                      response.data.first_payment_paid === false
                        ? "first_payment"
                        : response.data.second_payment_paid === false
                        ? "completed"
                        : prev.state.type,
                  },
                }
              : prev
          );
        } else {
          window.location.href = response.data;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return {
    state: {},
    actions: { setPayment },
  };
};

const ApplicationPaymentContext = createContext<any>({
  state: {},
  actions: {},
});

export const ApplicationPaymentContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <ApplicationPaymentContext.Provider value={value}>
      {children}
    </ApplicationPaymentContext.Provider>
  );
};

export default function useApplicationPaymentContext() {
  return useContext<ReturnType<typeof Context>>(ApplicationPaymentContext);
}
