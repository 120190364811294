import { CommonApi } from "./modules/common";
import { ProgramsApi } from "./modules/programs";
import { UniversityApi } from "./modules/university";
import { NewsApi } from "./modules/news";
import { EmployeeApi } from "./modules/employee";
import { BranchApi } from "./modules/branch";
import { UsersApi } from "./modules/users";
import { CityApi } from "./modules/city";
import { DocumentApi } from "./modules/document";
import { ApplicationsApi } from "./modules/applications";
import { PaymentMethodApi } from "./modules/paymentMethod";
import { TransactionTypeApi } from "./modules/transactionType";
import { CashboxApi } from "./modules/cashbox";
import { TransactionApi } from "./modules/transaction";
import { NotificationApi } from "./modules/notification";
import { AchievementsApi } from "./modules/achievements";

const api = {
  common: new CommonApi(),
  application: new ApplicationsApi(),
  university: new UniversityApi(),
  programs: new ProgramsApi(),
  news: new NewsApi(),
  achievements: new AchievementsApi(),
  employee: new EmployeeApi(),
  branch: new BranchApi(),
  users: new UsersApi(),
  city: new CityApi(),
  document: new DocumentApi(),
  paymentMethod: new PaymentMethodApi(),
  transactionType: new TransactionTypeApi(),
  cashbox: new CashboxApi(),
  transaction: new TransactionApi(),
  notification: new NotificationApi(),
};

export default api;
