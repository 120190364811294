import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import { Button, Container, Filter, Table } from "components/ui";
import { useNavigate } from "react-router-dom";
import useNewsContext, { INewsFilter } from "../services/newsContext";

const News = () => {
  const navigate = useNavigate();
  const {
    state: { filterFields, columns, data, loading, total },
    actions: { onRowClick },
  } = useNewsContext();
  return (
    <div>
      <Row>
        <Col xs={18} md={6}>
          <Button
            type="primary"
            style={{ backgroundColor: "dodgerblue" }}
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => navigate("/news/create")}
          >
            Yangilik qo'shish
          </Button>
        </Col>
        <Col xs={6} md={18}>
          <Filter<INewsFilter>
            filterFields={filterFields}
            onFinish={(values) => {
              console.log(values);
            }}
          />
        </Col>
      </Row>
      <Container padding={false} filter>
        <Table
          columns={columns}
          dataSource={data}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => onRowClick(record, rowIndex), // click row
              style: { cursor: "pointer" },
            };
          }}
          loading={loading}
          total={total}
        />
      </Container>
    </div>
  );
};

export default News;
