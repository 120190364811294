import { AchievementsAddContextProvider } from "../services/achievementsAddContext";
import AchievementsAdd from "./AchievementsAdd";

const index = () => {
  return (
    <AchievementsAddContextProvider>
      <AchievementsAdd />
    </AchievementsAddContextProvider>
  );
};

export default index;
