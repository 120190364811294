import {
  faArrowRightArrowLeft,
  faEdit,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Flex, Space, Typography } from "antd";
import { ICashbox } from "common/types/cashbox";
import { Button } from "components/ui";
import React from "react";
import { format } from "utils/format";
import useCashboxesContext from "../../services/cashboxesContext";
import { Link } from "react-router-dom";

interface ICashboxProps {
  selected?: boolean;
  cashbox: ICashbox;
}

const CashboxCard: React.FC<ICashboxProps> = ({
  selected = false,
  cashbox,
}) => {
  const {
    actions: { selectCashbox, handleAction },
  } = useCashboxesContext();
  const { balance, name, employee_id, _id, organization, employee } = cashbox;
  return (
    <Card
      className="cashbox-card"
      style={{
        transition: "all 0.3s ease-in-out",
        backgroundColor: selected ? "#00649C" : "#C2C2C2",
        cursor: "pointer",
      }}
      styles={{ body: { padding: 15 } }}
      onClick={() => selectCashbox(_id)}
    >
      <Typography.Title level={4} style={{ margin: 0, color: "white" }}>
        {format.money(balance, "UZS")}
      </Typography.Title>
      <Typography.Title
        level={5}
        style={{
          margin: 0,
          marginBottom: 10,
          fontWeight: "normal",
          color: "rgba(255, 255, 255, 0.5)",
        }}
      >
        {name}
      </Typography.Title>
      <Typography.Paragraph style={{ margin: 0, color: "white" }}>
        {organization?.name}
      </Typography.Paragraph>
      <Typography.Text style={{ color: "rgba(255, 255, 255, 0.5)" }}>
        {employee?.full_name}
      </Typography.Text>
      <div
        style={{
          marginTop: 10,
          transition: "overflow 0.3s ease-in-out, height 0.3s ease-in-out",
          height: selected ? 32 : 0,
          overflow: "hidden",
        }}
      >
        <Flex justify="space-between">
          <Link to={`/finance/cashboxes/create/${cashbox._id}`}>
            <Button
              icon={<FontAwesomeIcon icon={faEdit} />}
              type="dashed"
              shape="circle"
              style={{
                borderColor: "white",
                backgroundColor: "transparent",
                color: "white",
              }}
            />
          </Link>
          <Space>
            <Button
              icon={<FontAwesomeIcon icon={faPlus} />}
              type="primary"
              style={{
                backgroundColor: "#1EB53A",
              }}
              size="small"
              onClick={() => handleAction("income")}
            >
              Kirim
            </Button>
            <Button
              icon={<FontAwesomeIcon icon={faMinus} />}
              type="primary"
              danger
              size="small"
              onClick={() => handleAction("expense")}
            >
              Chiqim
            </Button>
            <Button
              icon={<FontAwesomeIcon icon={faArrowRightArrowLeft} />}
              type="primary"
              style={{
                backgroundColor: "#00d0ff",
              }}
              danger
              size="small"
              onClick={() => handleAction("transfer")}
            />
          </Space>
        </Flex>
      </div>
    </Card>
  );
};

export default CashboxCard;
