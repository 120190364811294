import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Flex, Row } from "antd";
import { Button, Container, Filter, Table } from "components/ui";
import useSalariesContext from "../services/salariesContext";
import Drawer from "../components/Drawer/Drawer";

const Salaries = () => {
  const {
    state: { filterFields, columns, data, loading, total },
    actions: { handleOpenDrawer },
  } = useSalariesContext();

  return (
    <div>
      <Flex justify="flex-end" style={{ marginBottom: 10 }}>
        <Button
          type="primary"
          style={{ backgroundColor: "dodgerblue" }}
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={handleOpenDrawer}
        >
          Maosh chiqarish
        </Button>
      </Flex>
      <Container padding={false} filter>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          total={total}
        />
      </Container>
      <Drawer />
    </div>
  );
};

export default Salaries;
